import {useEffect} from 'react'
import {useRouter} from 'next/router'

function isDataInvalid(data) {
  return typeof data.event !== 'string'
}

export function gtmPush(data) {
  if (!window || !window.dataLayer) return

  if (!isDataInvalid(data)) {
    window.dataLayer.push(data)
  }
}

export function logPageview(url) {
  gtmPush({
    event: 'Track Page View',
    path: url,
  })
}

export function logEvent({ga, fb}) {
  gtmPush({
    event: 'Track Custom Event',
    ga,
    fb,
  })
}

export function useGTMPageview() {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url, {shallow}) => {
      if (!shallow) {
        logPageview(url)
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])
}
