import React from 'react'
import get from 'lodash/get'
import className from 'classnames'
import smoothscroll from 'smoothscroll-polyfill'
import Head from 'next/head'
import {useRouter} from 'next/router'
import dynamic from 'next/dynamic'

import {t} from '@lib/helpers'
import '../app.scss'
import {useGTMPageview, UTMContextProvider} from '@lib/gtm'
import {CustomAiFooter} from '@components/custom-ai'

const Nav = dynamic(() => import('@components/Nav'))
const GetStarted = dynamic(() => import('@components/GetStarted'))
const Footer = dynamic(() => import('@components/Footer'))
const FooterWithForm = dynamic(() => import('@components/FooterWithForm'))
const Meta = dynamic(() => import('@components/Meta'))
const SiteHeader = dynamic(() => import('@components/SiteHeader'))
const RECAPTCHA_KEY = '6Lf2Lf0UAAAAAFNzNUIOvMeAvUTTad8_R6tIWUjJ'

const MyApp = (props) => {
  const router = useRouter()
  const [isActive, isActiveSet] = React.useState(false)
  const [isRecaptchaValidated, isRecaptchaValidatedSet] = React.useState(false)

  useGTMPageview()

  React.useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  React.useEffect(() => {
    const captcha = () => {
      const script = document.createElement('script')

      script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`
      script.addEventListener('load', () => {
        if (window.grecaptcha) {
          window.grecaptcha.ready(() => {
            window.grecaptcha
              .execute(RECAPTCHA_KEY, {action: 'homepage'})
              .then((_token) => {
                isRecaptchaValidatedSet(true)
              })
          })
        }
      })

      document.body.appendChild(script)
    }

    window.setTimeout(captcha, 4000)

    return () => window.clearTimeout(captcha)
  }, [])

  React.useEffect(() => {
    // Not to activate transition right away to prevent
    // first-load premature transition.
    isActiveSet(true)
  }, [])

  const demoPaths = [
    '/demo/demographic',
    '/demo/detection',
    '/demo/face-embedding',
    '/demo/face-verification-api',
    '/demo/face-verification',
    '/demo/faceblur',
    '/demo/ocr',
  ]

  const withFormPath = ['/services/data-labeling']
  const withCustomAiFormPath = ['/custom-ai']

  return (
    <React.Fragment>
      {(() => {
        const _host =
          typeof window != 'undefined'
            ? get(window, 'location.origin')
            : 'https://datawow.io'

        const url = `${_host}${get(router, 'asPath')}`

        return (
          <React.Fragment>
            <Meta
              title={t('layout.title')}
              description={t('layout.description')}
              image='banner.png'
            />

            <Head>
              <meta charSet='utf-8' />
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1, maximum-scale=1'
              />
              <meta name='robots' content='index,follow' />
              <meta name='googlebot' content='index,follow' />

              <meta name='og:url' property='og:url' content={url} />
              <meta name='og:type' property='og:type' content='website' />

              <meta name='twitter:card' content='summary_large_image' />
              <meta name='twitter:site' content={url} />
              {/* <meta name="twitter:creator" content="@" /> */}

              <link rel='preconnect' href='https://www.google-analytics.com' />
              <link rel='preconnect' href='https://stats.g.doubleclick.net' />

              <link rel='canonical' href={get(url.split('?'), '0')} />

              <link
                rel='apple-touch-icon'
                sizes='180x180'
                href='/apple-touch-icon.png'
              />
              <link
                rel='icon'
                type='image/png'
                sizes='32x32'
                href='/favicon-32x32.png'
              />
              <link
                rel='icon'
                type='image/png'
                sizes='16x16'
                href='/favicon-16x16.png'
              />
              <link rel='manifest' href='/site.webmanifest' />
              <link
                rel='mask-icon'
                href='/safari-pinned-tab.svg'
                color='#5bbad5'
              />
              <meta name='msapplication-TileColor' content='#ffffff' />
              <meta name='theme-color' content='#ffffff' />
            </Head>
          </React.Fragment>
        )
      })()}
      <UTMContextProvider>
        {demoPaths.includes(get(router, 'pathname')) ? (
          <props.Component
            {...{
              ...props.pageProps,
              isRecaptchaValidated,
            }}
          />
        ) : withFormPath.includes(get(router, 'pathname')) ? (
          <div
            className={className('Layout', {
              'Layout--active': isActive,
            })}
          >
            <SiteHeader />

            <props.Component
              {...{
                ...props.pageProps,
                isRecaptchaValidated,
              }}
            />

            <FooterWithForm />
          </div>
        ) : withCustomAiFormPath.includes(get(router, 'pathname')) ? (
          <div
            className={className('Layout', {
              'Layout--active': isActive,
            })}
          >
            <SiteHeader />

            <props.Component
              {...{
                ...props.pageProps,
                isRecaptchaValidated,
              }}
            />

            <CustomAiFooter />
          </div>
        ) : (
          <div
            className={className('Layout', {
              'Layout--active': isActive,
            })}
          >
            <SiteHeader />

            <props.Component
              {...{
                ...props.pageProps,
                isRecaptchaValidated,
              }}
            />

            <GetStarted isRecaptchaValidated={isRecaptchaValidated} />

            <Footer />
          </div>
        )}
      </UTMContextProvider>
      <div
        className='g-recaptcha'
        data-sitekey={RECAPTCHA_KEY}
        data-size='invisible'
      />
    </React.Fragment>
  )
}

export default MyApp
